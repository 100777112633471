import type { AppProps } from 'next/app';

import '../styles/index.css';
import '@sendbird/uikit-react/dist/index.css';
import React, { useEffect, useState } from 'react';
import { AnalyticsService } from '@/services/analyticsService';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import UserService from '@/services/userService';

// @ts-ignore
if (!Bugsnag._client) {
  Bugsnag.start({
    releaseStage:
      process.env.BUGSNAG_RELEASE_STAGE || process.env.NODE_ENV || 'production',
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production', 'staging'],
  });
}
const ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(React);

export default function App({ Component, pageProps, router }: AppProps) {
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await UserService.getInstance().getMe();
      setUserLoaded(true);
    })();

    AnalyticsService.getInstance().trackPage(router.asPath);
    // Cache current user
    const handleRouteChange = (url: string) => {
      AnalyticsService.getInstance().trackPage(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    // @ts-ignore
    <ErrorBoundary>{userLoaded && <Component {...pageProps} />}</ErrorBoundary>
  );
}
