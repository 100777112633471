import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  ApolloClientOptions,
} from '@apollo/client';
export default class GraphQLService {
  private static authToken?: string = undefined;

  private static instance?: GraphQLService;

  private client?: ApolloClient<any>;

  public static getInstance(): GraphQLService {
    if (!GraphQLService.instance) {
      GraphQLService.instance = new GraphQLService();
    }
    return GraphQLService.instance;
  }

  public static setAuthToken(token?: string) {
    GraphQLService.authToken = token;
    // We have to do this as the auth token is set when
    // the client is constructed, which in some cases
    // happens before we set the token
    GraphQLService.getInstance().constructGraphQLClient();
  }

  private constructor() {
    this.constructGraphQLClient();
  }

  private constructGraphQLClient() {
    const authLink = new ApolloLink((operation, forward) => {
      operation.setContext(this.createApolloLinkContext());
      return forward(operation);
    });
    this.client = new ApolloClient({
      link: authLink.concat(
        createHttpLink({
          uri: this.createGraphQLURL(),
        }),
      ),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: GraphQLService.authToken ? 'no-cache' : 'cache-first',
        },
      },
    });
  }

  public getClient(): ApolloClient<any> {
    return this.client as ApolloClient<any>;
  }

  public createApolloLinkContext(): any {
    return {
      headers: {
        authorization: GraphQLService.authToken
          ? `Bearer ${GraphQLService.authToken}`
          : '',
      },
    };
  }

  public createGraphQLURL(): string {
    let onServer: boolean = typeof window === 'undefined';
    let host: string = (
      onServer
        ? process.env.NEXT_PUBLIC_RAILS_ENDPOINT
        : process.env.NEXT_PUBLIC_API_ENDPOINT
    ) as string;
    let fragment: string = onServer ? '' : 'api/';
    return `${host}/${fragment}graphql`;
  }
}
