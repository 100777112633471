import GraphQLService from '@/services/graphQLService';

import {
  ClaimUsernameInput,
  ConfirmEmailInput,
  ConfirmInitialEmailInput,
  LoginInput,
  ResendInitialEmailConfirmationInput,
  User,
} from '../../graphql/generated';
import UserQueries from '@/queries/userQueries';
import axios from 'axios';

export type UserIntent = {
  intent?: string;
  target?: any;
};

export default class UserService {
  private static instance: UserService;

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  private userIntent: UserIntent = {};

  private muteVerificationStatus: boolean = false;

  private bypassCacheForMeQuery: boolean = false;

  private isLoggedInStatus: boolean = false;

  private cachedMe?: User = undefined;

  private constructor() {}

  public isLoggedIn(): boolean {
    return this.isLoggedInStatus;
  }

  public clearCachedMe() {
    this.cachedMe = undefined;
  }

  public async getMe(): Promise<User> {
    let bypassCache = {};
    if (this.bypassCacheForMeQuery) {
      bypassCache = { fetchPolicy: 'no-cache' };
    }
    if (!bypassCache && this.cachedMe) {
      return this.cachedMe;
    }
    let response = await GraphQLService.getInstance()
      .getClient()
      .query({ query: UserQueries.ME_QUERY, ...bypassCache });
    if (response?.data?.me?.username) {
      this.isLoggedInStatus = true;
    }
    this.cachedMe = response?.data.me;
    return this.getCachedMe() as User;
  }

  public storeUserIntent(intent: UserIntent) {
    this.userIntent = intent;
    window.localStorage.setItem('INTENT', JSON.stringify(this.userIntent));
  }

  public getIntent(): UserIntent {
    if (window) {
      if (Object.keys(this.userIntent).length === 0) {
        let item = window.localStorage.getItem('INTENT');
        if (item) {
          try {
            this.userIntent = JSON.parse(item);
          } catch (e: any) {
            console.log(e);
          }
        }
      }
    }
    return this.userIntent;
  }

  public clearIntent() {
    this.userIntent = {};
    window.localStorage.setItem('INTENT', JSON.stringify(this.userIntent));
  }

  public setBypassCacheForMeQuery(flag: boolean) {
    this.bypassCacheForMeQuery = flag;
  }

  public getMuteVerificationStatus(): boolean {
    return this.muteVerificationStatus;
  }

  public setMuteVerificationStatus(flag: boolean) {
    this.muteVerificationStatus = flag;
  }

  public async isMe(username: string): Promise<boolean> {
    if (!username) return false;
    let me = await this.getMe();
    return username === me?.username;
  }

  public async checkUsernameAvailability(username: string): Promise<boolean> {
    let response = await GraphQLService.getInstance().getClient().query({
      query: UserQueries.USERNAME_AVAILABLE_QUERY,
      variables: { username },
    });
    return response?.data?.usernameAvailable;
  }

  public async claimUsername(username: string): Promise<boolean> {
    let claimUsernameInput: ClaimUsernameInput = { username: username };
    let queryResult = await GraphQLService.getInstance()
      .getClient()
      .mutate({
        mutation: UserQueries.CLAIM_USERNAME,
        variables: { input: claimUsernameInput },
      });
    let result = queryResult.data.claimUsername.errors?.length === 0;
    if (!result) {
      throw new Error(JSON.stringify(queryResult.data.claimUsername.errors));
    }
    UserService.getInstance().setBypassCacheForMeQuery(true);
    return result;
  }

  public async swapTokenForCookie(authToken: string) {
    await axios.post(process.env.NEXT_PUBLIC_API_ENDPOINT + '/api/token', {
      token: authToken,
    });
  }

  public async login(username: string, password: string): Promise<boolean> {
    let loginCredentials: LoginInput = {
      email: username,
      password,
    };
    let response = await GraphQLService.getInstance()
      .getClient()
      .mutate({
        mutation: UserQueries.LOGIN_MUTATION,
        variables: { input: loginCredentials },
      });
    if (response.data.login.token) {
      await this.swapTokenForCookie(response.data.login.token);
      UserService.getInstance().setBypassCacheForMeQuery(true);
      await UserService.getInstance().getMe();
      return true;
    }
    return false;
  }

  public getCachedMe() {
    return this.cachedMe;
  }

  public async verifyAccount(
    token: string,
    password: string,
    passwordConfirm: string,
  ): Promise<boolean> {
    let input: ConfirmInitialEmailInput = {
      emailConfirmationToken: token,
      password,
      passwordConfirmation: passwordConfirm,
    };
    let response = await GraphQLService.getInstance().getClient().mutate({
      mutation: UserQueries.CONFIRM_INITIAL_EMAIL_MUTATION,
      variables: {
        input,
      },
    });
    if (
      response.data.confirmInitialEmail.errors &&
      response.data.confirmInitialEmail.errors.length > 0
    ) {
      let responseErrors = response.data.confirmInitialEmail.errors;
      if (responseErrors[0].type === 'notPwned') {
        throw new Error('PWNED');
      }
      if (responseErrors[0].type === 'invalid') {
        throw new Error('INVALID');
      }
      return false;
    }
    let authToken: string = response.data.confirmInitialEmail.token;
    await this.swapTokenForCookie(authToken);
    return true;
  }

  public async verifyEmail(token: string): Promise<boolean> {
    let input: ConfirmEmailInput = {
      emailConfirmationToken: token,
    };
    let response = await GraphQLService.getInstance().getClient().mutate({
      mutation: UserQueries.CONFIRM_EMAIL_MUTATION,
      variables: { input },
    });
    if (response.data.confirmEmail?.token) {
      GraphQLService.setAuthToken(response.data.confirmEmail.token);
      return response.data.confirmEmail?.token;
    }
    return false;
  }

  public async resendVerification(id: string) {
    let input: ResendInitialEmailConfirmationInput = {
      userId: id,
    };
    await GraphQLService.getInstance().getClient().mutate({
      mutation: UserQueries.RESEND_VERIFICATION_MUTATION,
      variables: { input },
    });
  }
}
