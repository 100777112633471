import { gql } from '@apollo/client';

const UserQueries = {
  ME_QUERY: gql`
    query {
      me {
        id
        username
        emailConfirmed
        isAdmin
        profile {
          username
          displayName
          avatarUrl
          bio
        }
        organizations {
          id
          avatarUrl
          displayName
          name
          subscriberCount
          channels {
            id
            title
            imageUrl
            subscriberCount
            url
          }
        }
      }
    }
  `,
  USERNAME_AVAILABLE_QUERY: gql`
    query UsernameAvailableQuery($username: String!) {
      usernameAvailable(username: $username)
    }
  `,

  CLAIM_USERNAME: gql`
    mutation Mutation($input: ClaimUsernameInput!) {
      claimUsername(input: $input) {
        errors {
          path
          type
        }
        user {
          id
          username
        }
      }
    }
  `,
  CONFIRM_INITIAL_EMAIL_MUTATION: gql`
    mutation Mutation($input: ConfirmInitialEmailInput!) {
      confirmInitialEmail(input: $input) {
        errors {
          path
          type
        }
        token
      }
    }
  `,
  CONFIRM_EMAIL_MUTATION: gql`
    mutation Mutation($input: ConfirmEmailInput!) {
      confirmEmail(input: $input) {
        errors {
          path
          type
        }
        token
      }
    }
  `,
  RESEND_VERIFICATION_MUTATION: gql`
    mutation Mutation($input: ResendInitialEmailConfirmationInput!) {
      resendInitialEmailConfirmation(input: $input) {
        resent
      }
    }
  `,
  LOGIN_MUTATION: gql`
    mutation Mutation($input: LoginInput!) {
      login(input: $input) {
        token
      }
    }
  `,
  LOGOUT: gql`
    mutation Mutation($input: LogoutInput!) {
      logout(input: $input) {
        loggedOut
      }
    }
  `,
  FORGOT_PASSWORD: gql`
    mutation Mutation($input: ForgotPasswordInput!) {
      forgotPassword(input: $input) {
        sent
      }
    }
  `,
  RESET_PASSWORD: gql`
    mutation Mutation($input: ResetPasswordInput!) {
      resetPassword(input: $input) {
        errors {
          type
          path
        }
        token
      }
    }
  `,
};

export default UserQueries;
