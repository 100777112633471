import { FirebaseApp, initializeApp } from '@firebase/app';
import { Analytics, getAnalytics, logEvent } from '@firebase/analytics';
import UserService from '@/services/userService';
import axios from 'axios';

const pageMappings: any = {
  '/': 'landingPage',
  'r\\/join(?:\\?.*)': 'enterEmail',
  '/login': 'login',
  'r\\/jobs\\/apply(.*)': 'jobApplication',
  'r\\/jobs\\/(?:[^\\/]*)': 'jobPosting',
  '/jobs/application-sent': 'jobApplicationSent',
  '/join/username': 'enterUsername',
  '/join/setup': 'onboardingRoles',
  '/join/work': 'onboardingAddWork',
  '/join/avatar': 'onboadingAvatar',
  'r\\/@(?:[^\\/])*': 'publicProfile',
  'r\\/@(?:[^\\/])+\\/work\\/(?:.*)+': 'publicProjectDetails',
  'r\\/app\\/work\\/add(?:\\?.*)': 'workAddPage',
  '/forgot-password': 'forgotPassword',
  '/confirm-initial-email': 'confirmAccount',
  '/reset-password': 'resetPassword',
};

export type AnalyticsEvent = {
  event_data?: any;
  session_id?: string;
  user_id?: string;
};

type Properties = {
  [index: string]: string | number | boolean;
};

type Event = {
  id: string;
  name: string;
  anonymousId: string;
  userId: string;
  properties: Properties;
  context: Properties;
  timestamp: Date;
};

const PRODUCT_EVENTS_ENDPOINT = process.env.NEXT_PUBLIC_EVENTS_ENDPOINT;

export class AnalyticsService {
  private static instance?: AnalyticsService;

  private analytics: Analytics;
  private readonly firebaseApp: FirebaseApp;

  private static readonly SESSION_ID_TTL = 3600 * 2;

  private sessionId?: string;
  private sessionIdLastTouched: number = 0;

  private constructor() {
    const firebaseConfig = JSON.parse(
      process.env.NEXT_PUBLIC_FIREBASE_CONFIG as string,
    );
    this.sessionId = this.getSessionID();
    this.firebaseApp = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.firebaseApp);
  }

  public static getAnalytics(): Analytics {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService();
    }
    return AnalyticsService.instance.analytics;
  }

  public getFirebaseApp(): FirebaseApp {
    return this.firebaseApp;
  }

  public static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService();
    }
    return AnalyticsService.instance;
  }

  private createUUID() {
    if (self?.crypto?.randomUUID) {
      return self.crypto.randomUUID();
    }
    // @ts-ignore
    let val = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
    );
    return val as string;
  }

  public getSessionID(): string {
    if (
      !this.sessionId ||
      this.sessionIdLastTouched + AnalyticsService.SESSION_ID_TTL <
        new Date().getTime()
    )
      this.sessionId = this.createUUID();
    this.sessionIdLastTouched = new Date().getTime();
    return this.sessionId;
  }

  public trackProductEvent(
    name: string,
    properties: Properties,
    context: Properties,
  ) {
    if (typeof window === 'undefined') {
      console.log(
        'AnalyticsService.trackProductEvent should only be used on client side',
      );
      return;
    }
    (async () => {
      if (!context) {
        context = {};
      }
      const intent = UserService.getInstance().getIntent();
      if (intent?.intent) {
        context['active_intent'] = intent.intent;
        context['intent_target_id'] = intent.target?.id as string;
      }
      context['session_id'] = this.getSessionID();
      const me = await UserService.getInstance().getMe();
      let event: Event = {
        id: this.createUUID(),
        name,
        properties,
        context,
        userId: me?.id,
        anonymousId: this.getSessionID(),
        timestamp: new Date(),
      };
      await axios.post(`/api/events`, event);
    })();
  }

  public trackPage(url: string) {
    if (!pageMappings[url]) {
      if (
        !Object.keys(pageMappings)
          .filter((p) => p.startsWith('r'))
          .some((regexUrl: string) => {
            let regex = regexUrl.substring(1);
            if (new RegExp(regex).test(url)) {
              track(`view_${pageMappings[regexUrl]}`, {});
              return true;
            }
          }) &&
        process.env.NEXT_PUBLIC_WARN_MISSING_PAGEMAP
      ) {
        console.warn('Unmapped page: ', url);
      }
      return;
    }
    track(`view_${pageMappings[url]}`, {});
  }
}

export default function track(event: string, properties: AnalyticsEvent) {
  (async () => {
    if (!properties.session_id) {
      properties.session_id = AnalyticsService.getInstance().getSessionID();
    }
    if (!properties.user_id) {
      let me = await UserService.getInstance().getMe();
      properties.user_id = me?.id || '00000000-0000-0000-0000-000000000000';
    }
    logEvent(AnalyticsService.getAnalytics(), event, properties);
  })();
}
